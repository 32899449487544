import { mapGetters, mapActions } from "vuex";
import bookingPresetApi from '@/apis/liff/v2/booking-preset';
import bookingShiftApi from "@/apis/liff/v2/booking-shift";
import branchApi from '@/apis/liff/v2/branch';
import staffApi from '@/apis/liff/v2/staff';
import _ from 'lodash';

const MODULE_CODE = "liff_reservation";

export default {
  computed: {
    ...mapGetters({
      service: "liffReservation/service",
      schedule: "liffReservation/schedule",
      staff: "liffReservation/staff",
      branch: "liffReservation/branch",
      otherData: "liffReservation/otherData",
      stepList: "liffReservation/stepList",
      preset: "liffReservation/preset",
      user: "liffAuth/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchMe: 'liffAuth/fetchMe',
      setBranchData: 'liffReservation/setBranchData',
      setStaffData: 'liffReservation/setStaffData',
      setServiceData: 'liffReservation/setServiceData',
      setScheduleData: 'liffReservation/setScheduleData',
      setOtherData: 'liffReservation/setOtherData',
      setPresetData: 'liffReservation/setPresetData',
      initStepList: 'liffReservation/initStepList',
    }),
    async fetchPresetData(bookingPresetId, branchId = null) {
      if (!(this.preset.id == bookingPresetId && this.branch.id == branchId)) {
        const data = await bookingPresetApi.get(bookingPresetId, {
          branch_id: branchId
        });
        this.setPresetData(data);
        return data
      } else {
        return this.preset;
      }
    },
    async fetchStaffShift(query) {
      return await bookingShiftApi.get({
        booking_preset_id: query.booking_preset_id,
        staff_id: query.staff_id,
        start_at: query.start_at,
        end_at: query.end_at,
      });
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    initStepFromBookingPreset(bookingPreset) {
      this.initStepList(bookingPreset);
    },
    nextStepByList(currentRouteName, query) {
      let currentIndex = this.stepList.findIndex(step => step.name === currentRouteName);
      let nextIndex = currentIndex + 1;

      while (nextIndex < this.stepList.length && !this.stepList[nextIndex].enabled) {
        nextIndex++;
      }

      if (nextIndex < this.stepList.length) {
        this.$router.push({ name: this.stepList[nextIndex].name, query: query, });
      }
    },
    checkHasPrevStep(currentRouteName) {
      let currentIndex = this.stepList.findIndex(step => step.name === currentRouteName);
      let prevIndex = currentIndex - 1;

      while (prevIndex >= 0 && !this.stepList[prevIndex].enabled) {
        prevIndex--;
      }

      return this.stepList[prevIndex].name != 'LiffReservation'
    },
    prevStepByList(currentRouteName, query) {
      let currentIndex = this.stepList.findIndex(step => step.name === currentRouteName);
      let prevIndex = currentIndex - 1;

      while (prevIndex >= 0 && !this.stepList[prevIndex].enabled) {
        prevIndex--;
      }

      if (prevIndex >= 0) {
        this.$router.push({ name: this.stepList[prevIndex].name, query: query, });
      }
    },
    async get(type, hashid = null) {
      if (type == 'branch') {
        if (!this.branch.id) {
          const { data } = await branchApi.getSingleBranch(hashid)

          // 找出分店提供者
          const provider = _.get(this.preset, 'config.branch.field_id', "");

          let branchProvider = "waltily.branch";

          console.log("provider", provider);

          if (provider) {
            const branchField = _.get(this.preset, 'config.fields', []).find((field) => { return field._id == provider })
            console.log("branchField", branchField);
            branchProvider = branchField?.branch_provider
          }

          console.log("Set branch trigged");

          this.set('branch', {
            id: data.data.id,
            name: data.data.name,
            model: {
              branch_provider: branchProvider,
              text: data.data.name,
              value: data.data.id,
            }
          });

          console.log("Set branch done", this.branch);
        }
        return this.branch;
      } else if (type == 'staff') {
        if (!this.staff.id) {
          const data = await staffApi.getStaff(hashid)
          this.set('staff', data)
        }
        return this.staff
      } else if (type == 'service') {
        return this.service
      } else if (type == 'schedule') {
        return this.schedule
      } else if (type == 'other') {
        return this.otherData
      }
    },
    set(type, item) {
      if (type == 'branch') {
        this.setBranchData(item)
      } else if (type == 'staff') {
        this.setStaffData(item)
      } else if (type == 'service') {
        this.setServiceData(item)
      } else if (type == 'schedule') {
        this.setScheduleData(item)
      } else if (type == 'other') {
        this.setOtherData(item)
      }
    }
  }
}
