import https from "./https";
import store from "@/store";

const bookingShiftApi = {
  async get(params = {}) {
    const organization = store.state.liffGeneral.orgCode;

    if (!params.shift_type) {
      params.shift_type = "leave";
    }

    let { data } = await https.get(
      `${organization}/liff/booking-shifts`, {
        params
      }
    );

    return data.data;
  },
};

export default bookingShiftApi;
